function getClass(val) {
  return `bg-[${val}]`;
}

function getSecond(secondTs) {
  const timeParts = secondTs.split(":").map(Number);
  return Math.round(
    timeParts[0] * 3600 +
      timeParts[1] * 60 +
      (timeParts.length >= 3 ? timeParts[2] : 0)
  );
}

function getSeconds(ts) {
  const secondsArray = [];

  ts.forEach((i) => {
    const second = this.getSecond(i);
    secondsArray.push(second);
  });

  return secondsArray;
}

export { getClass, getSecond, getSeconds };
