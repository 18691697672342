<template>
  <HomeLayoutComponent>
    <div class="w-full min-h-[60px] flex justify-center items-center relative">
      <BackButtonComponent />
      <HorseInfoComponent
        v-if="horseTrackingCard"
        :horseTrackingCard="horseTrackingCard"
      />
    </div>
    <TrainingsComparisonComponent />
  </HomeLayoutComponent>
</template>

<script>
import HomeLayoutComponent from "@/components/layouts/HomeLayoutComponent";
import TrainingsComparisonComponent from "@/components/training/comparison/TrainingsComparisonComponent.vue";
import BackButtonComponent from "@/components/BackButtonComponent.vue";
import HorseInfoComponent from "@/components/training/HorseInfoComponent.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { showErrorAndGoToHomePage } from "@/store/storeUtils";

export default {
  name: "TrainingsComparisonView",
  components: {
    HorseInfoComponent,
    TrainingsComparisonComponent,
    HomeLayoutComponent,
    BackButtonComponent,
  },
  computed: {
    ...mapGetters(["horseTrackingCard"]),
  },
  methods: {
    ...mapActions(["getHorsesList", "getTrainingsDates"]),
    ...mapMutations(["SET_LOADING", "SET_HORSE_TRACKING_CARD"]),
    async setCurrentHorse() {
      await this.$nextTick();
      if (this.horsesList) {
        const currentHorse = this.horsesList.find(
          (h) => h.id === Number(this.$route.params.id)
        );
        if (!currentHorse) {
          return showErrorAndGoToHomePage("Horse not found");
        }
        this.SET_HORSE_TRACKING_CARD(currentHorse);
      }
    },
  },

  async mounted() {
    if (!this.horsesList) await this.getHorsesList(this.$route.params.page);
    if (!this.horseTrackingCard) await this.setCurrentHorse();
    await this.getTrainingsDates(this.$route.params.id);
  },
};
</script>
