<template>
  <div
    class="w-full mx-auto my-4 p-4 flex justify-between flex-wrap bg-white shadow rounded-lg relative"
  >
    <div
      v-if="!loading && !filteredHistData.length"
      class="w-full my-8 flex justify-center items-center"
    >
      <span>{{ $t("comparison.Nothing to compare") }}.</span>
    </div>
    <LoaderComponent v-if="loading && !filteredHistData.length" />
    <div
      v-if="filteredHistData.length"
      class="w-full my-4 flex flex-wrap justify-center items-center"
    >
      <p
        class="w-full text-[#5A5A5F] text-[18px] font-semibold text-center my-4"
      >
        {{ $t("comparison.Max and average hist value history") }}
      </p>
      <div
        v-for="(i, index) of filteredHistData"
        :key="i + index"
        class="w-full flex flex-wrap md:flex-nowrap justify-center items-center"
      >
        <div
          v-for="(i, index) of i.data"
          :key="i + index"
          class="m-4 w-full md:w-[29%]"
        >
          <p
            class="w-full text-[#5A5A5F] text-[16px] font-poppins font-semibold text-center"
          >
            {{ $t("horse." + i.leg) + "/" + $t("tracking." + i.movement) }}
          </p>
          <Line :data="i.chartData" :options="histOptions" />
        </div>
      </div>

      <p
        class="w-full text-[#5A5A5F] text-[18px] font-semibold text-center mb-4 mt-8"
      >
        {{ $t("comparison.Skewness value history") }}
      </p>
      <div
        v-for="(i, index) of filteredSkewnessData"
        :key="i + index"
        class="w-full flex flex-wrap md:flex-nowrap justify-center items-center"
      >
        <div
          v-for="(i, index) of i.data"
          :key="i + index"
          class="m-4 w-full md:w-[29%]"
        >
          <p
            class="w-full text-[#5A5A5F] text-[16px] font-poppins font-semibold text-center"
          >
            {{ $t("horse." + i.leg) + "/" + $t("tracking." + i.movement) }}
          </p>
          <Line :data="i.chartData" :options="skewnessOptions" />
        </div>
      </div>

      <div class="w-full flex flex-wrap justify-between mt-4">
        <div class="max-w-[450px] flex justify-between items-center">
          <div
            v-for="(item, index) of legs"
            :key="index"
            class="flex items-center ml-4"
          >
            <input
              type="checkbox"
              class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
              :id="item.title + title"
              v-model="item.checked"
            />
            <label
              :for="item.title + title"
              class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2"
            >
              <span>{{ $t("horse." + item.title) }}</span>
            </label>
          </div>
        </div>
        <div class="max-w-[450px] flex justify-between items-center">
          <div
            v-for="(item, index) of movements"
            :key="index"
            class="flex items-center ml-4"
          >
            <input
              type="checkbox"
              class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
              :id="item.title + title"
              v-model="item.checked"
            />
            <label
              :for="item.title + title"
              class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2"
            >
              <span>{{ $t("tracking." + item.title) }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
  TimeScale,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  TimeScale
);

import "v-calendar/style.css";
import { format } from "date-fns";
import LoaderComponent from "@/components/LoaderComponent.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { enUS, ru } from "date-fns/locale";
export default {
  name: "SkewnessComparisonComponent",
  components: {
    Line,
    LoaderComponent,
  },
  data() {
    return {
      movements: [
        { title: "Walk", checked: true },
        { title: "Trot", checked: true },
        { title: "Gallop", checked: true },
      ],
      legs: [
        { title: "FL", checked: true },
        { title: "FR", checked: true },
        { title: "HL", checked: true },
        { title: "HR", checked: true },
      ],
      // options: ,
      mappedHistData: [],
      mappedSkewnessData: [],
      labels: [],
    };
  },
  watch: {
    skewnessHistory() {
      if (this.skewnessHistory) {
        this.setMappedHistSkewnessData();
      }
    },
  },
  computed: {
    ...mapGetters(["skewnessHistory", "loading"]),
    histOptions() {
      return {
        animation: false,
        spanGaps: false,
        decimation: false,
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          crosshair: false,
          datalabels: false,
          tooltip: {
            callbacks: {
              title: (tooltipItem) => {
                const date = new Date(tooltipItem[0].label);
                const locale = this.$i18n.locale === "Ru" ? ru : enUS;
                return format(date, "dd MMM yy", { locale: locale });
              },
              label: (el) => {
                if (el.parsed.y > 0)
                  return (
                    this.$t("tracking." + el.dataset.description) +
                    " " +
                    el.parsed.y
                  );
                else {
                  return el.dataset.description === "Max hist value "
                    ? ""
                    : this.$t(
                        "tracking.This tracking does not include this type of walking"
                      );
                }
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              major: true,
              callback: (val) => {
                return format(this.labels[val], "dd-MM-yy");
              },
            },
          },
          y: {
            max: this.maxHistValue,
          },
        },
      };
    },
    skewnessOptions() {
      return {
        animation: false,
        spanGaps: false,
        decimation: false,
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          crosshair: false,
          datalabels: false,
          tooltip: {
            callbacks: {
              title: (tooltipItem) => {
                const date = new Date(tooltipItem[0].label);
                const locale = this.$i18n.locale === "Ru" ? ru : enUS;
                return format(date, "dd MMM yy", { locale: locale });
              },
              label: (el) => {
                if (el.parsed.y > 0)
                  return el.dataset.description + el.parsed.y;
                else {
                  return el.dataset.description === "Max hist value "
                    ? ""
                    : this.$t(
                        "tracking.This tracking does not include this type of walking"
                      );
                }
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              major: true,
              callback: (val) => {
                return format(this.labels[val], "dd-MM-yy");
              },
            },
          },
          y: {
            max: this.maxSkewnessValue,
          },
        },
      };
    },
    maxHistValue() {
      if (this.mappedHistData.length) {
        const data = this.mappedHistData.map((i) => i.data);
        const res = [];
        data.map((i) => {
          i.map((m) => res.push(...m.chartData.datasets[0].data));
        });
        return Math.max(...res);
      } else {
        return 0;
      }
    },
    maxSkewnessValue() {
      if (this.mappedSkewnessData.length) {
        const data = this.mappedSkewnessData.map((i) => i.data);
        const res = [];
        data.map((i) => {
          i.map((m) => res.push(...m.chartData.datasets[0].data));
        });
        return Math.max(...res);
      } else {
        return 0;
      }
    },
    filteredHistData() {
      const filteredMovements = this.movements
        .filter((i) => i.checked)
        .map((i) => i.title);
      const filteredLegs = this.legs
        .filter((i) => i.checked)
        .map((i) => i.title);
      const c = this.mappedHistData.map((i) => {
        return {
          title: i.title,
          data: i.data.filter((t) => filteredMovements.includes(t.movement)),
        };
      });

      return c.filter((i) => filteredLegs.includes(i.title));
    },

    filteredSkewnessData() {
      const filteredMovements = this.movements
        .filter((i) => i.checked)
        .map((i) => i.title);
      const filteredLegs = this.legs
        .filter((i) => i.checked)
        .map((i) => i.title);

      const c = this.mappedSkewnessData.map((i) => {
        return {
          title: i.title,
          data: i.data.filter((t) => filteredMovements.includes(t.movement)),
        };
      });

      return c.filter((i) => filteredLegs.includes(i.title));
    },

    formattedStartFrom() {
      return this.range.start ? format(this.range.start, "dd MMM yyyy") : null;
    },
    formattedEndTo() {
      return this.range.end ? format(this.range.end, "dd MMM yyyy") : null;
    },
  },
  methods: {
    ...mapActions(["getSkewnessHistory"]),
    ...mapMutations(["SET_LOADING"]),
    setMappedHistSkewnessData() {
      const sortedData = this.skewnessHistory.sort(
        (a, b) => a.trainingDate - b.trainingDate
      );
      this.labels = sortedData.map((i) => i.trainingDate);
      this.mappedHistData = [
        {
          title: "FL",
          data: [
            this.setMappedData(
              sortedData.map((i) => i.trainingData.walk.FL),
              this.labels,
              "FL",
              "Walk",
              "FL/Walk",
              "#6A9A53",
              "#7CFC00",
              "hist"
            ),
            this.setMappedData(
              sortedData.map((i) => i.trainingData.trot.FL),
              this.labels,
              "FL",
              "Trot",
              "FL/Trot",
              "#6A9A53",
              "#7CFC00",
              "hist"
            ),
            this.setMappedData(
              sortedData.map((i) => i.trainingData.gallop.FL),
              this.labels,
              "FL",
              "Gallop",
              "FL/Gallop",
              "#6A9A53",
              "#7CFC00",
              "hist"
            ),
          ],
        },
        {
          title: "FR",
          data: [
            this.setMappedData(
              sortedData.map((i) => i.trainingData.walk.FR),
              this.labels,
              "FR",
              "Walk",
              "FR/Walk",
              "#880808",
              "#E0712F",
              "hist"
            ),
            this.setMappedData(
              sortedData.map((i) => i.trainingData.trot.FR),
              this.labels,
              "FR",
              "Trot",
              "FR/Trot",
              "#880808",
              "#E0712F",
              "hist"
            ),
            this.setMappedData(
              sortedData.map((i) => i.trainingData.gallop.FR),
              this.labels,
              "FR",
              "Gallop",
              "FR/Gallop",
              "#880808",
              "#E0712F",
              "hist"
            ),
          ],
        },
        {
          title: "HL",
          data: [
            this.setMappedData(
              sortedData.map((i) => i.trainingData.walk.BL),
              this.labels,
              "HL",
              "Walk",
              "HL/Walk",
              "#0000CD",
              "#587DC0",
              "hist"
            ),
            this.setMappedData(
              sortedData.map((i) => i.trainingData.trot.BL),
              this.labels,
              "HL",
              "Trot",
              "HL/Trot",
              "#0000CD",
              "#587DC0",
              "hist"
            ),
            this.setMappedData(
              sortedData.map((i) => i.trainingData.gallop.BL),
              this.labels,
              "HL",
              "Gallop",
              "HL/Gallop",
              "#0000CD",
              "#587DC0",
              "hist"
            ),
          ],
        },
        {
          title: "HR",
          data: [
            this.setMappedData(
              sortedData.map((i) => i.trainingData.walk.BR),
              this.labels,
              "HR",
              "Walk",
              "HR/Walk",
              "#d58800",
              "#FFCD4B",
              "hist"
            ),
            this.setMappedData(
              sortedData.map((i) => i.trainingData.trot.BR),
              this.labels,
              "HR",
              "Trot",
              "HR/Trot",
              "#d58800",
              "#FFCD4B",
              "hist"
            ),
            this.setMappedData(
              sortedData.map((i) => i.trainingData.gallop.BR),
              this.labels,
              "HR",
              "Gallop",
              "HR/Gallop",
              "#d58800",
              "#FFCD4B",
              "hist"
            ),
          ],
        },
      ];
      this.mappedSkewnessData = [
        {
          title: "FL",
          data: [
            this.setMappedData(
              sortedData.map((i) => i.trainingData.walk.FL),
              this.labels,
              "FL",
              "Walk",
              "FL/Walk",
              "#6A9A53",
              "#7CFC00"
            ),
            this.setMappedData(
              sortedData.map((i) => i.trainingData.trot.FL),
              this.labels,
              "FL",
              "Trot",
              "FL/Trot",
              "#6A9A53",
              "#7CFC00"
            ),
            this.setMappedData(
              sortedData.map((i) => i.trainingData.gallop.FL),
              this.labels,
              "FL",
              "Gallop",
              "FL/Gallop",
              "#6A9A53",
              "#7CFC00"
            ),
          ],
        },
        {
          title: "FR",
          data: [
            this.setMappedData(
              sortedData.map((i) => i.trainingData.walk.FR),
              this.labels,
              "FR",
              "Walk",
              "FR/Walk",
              "#880808",
              "#E0712F"
            ),
            this.setMappedData(
              sortedData.map((i) => i.trainingData.trot.FR),
              this.labels,
              "FR",
              "Trot",
              "FR/Trot",
              "#880808",
              "#E0712F"
            ),
            this.setMappedData(
              sortedData.map((i) => i.trainingData.gallop.FR),
              this.labels,
              "FR",
              "Gallop",
              "FR/Gallop",
              "#880808",
              "#E0712F"
            ),
          ],
        },
        {
          title: "HL",
          data: [
            this.setMappedData(
              sortedData.map((i) => i.trainingData.walk.BL),
              this.labels,
              "HL",
              "Walk",
              "HL/Walk",
              "#0000CD",
              "#587DC0"
            ),
            this.setMappedData(
              sortedData.map((i) => i.trainingData.trot.BL),
              this.labels,
              "HL",
              "Trot",
              "HL/Trot",
              "#0000CD",
              "#587DC0"
            ),
            this.setMappedData(
              sortedData.map((i) => i.trainingData.gallop.BL),
              this.labels,
              "HL",
              "Gallop",
              "HL/Gallop",
              "#0000CD",
              "#587DC0"
            ),
          ],
        },
        {
          title: "HR",
          data: [
            this.setMappedData(
              sortedData.map((i) => i.trainingData.walk.BR),
              this.labels,
              "HR",
              "Walk",
              "HR/Walk",
              "#d58800",
              "#FFCD4B"
            ),
            this.setMappedData(
              sortedData.map((i) => i.trainingData.trot.BR),
              this.labels,
              "HR",
              "Trot",
              "HR/Trot",
              "#d58800",
              "#FFCD4B"
            ),
            this.setMappedData(
              sortedData.map((i) => i.trainingData.gallop.BR),
              this.labels,
              "HR",
              "Gallop",
              "HR/Gallop",
              "#d58800",
              "#FFCD4B"
            ),
          ],
        },
      ];
    },
    setSkewnessValue(val) {
      return typeof val.skewness === "number" ? val.skewness.toFixed(2) : 0;
    },
    setMappedData(
      data,
      labels,
      leg,
      movement,
      title,
      color,
      maxValColor,
      type
    ) {
      return {
        title: title,
        leg: leg,
        movement: movement,
        chartData: {
          labels: labels,
          datasets:
            type === "hist"
              ? [
                  {
                    backgroundColor: maxValColor,
                    borderColor: maxValColor,
                    description: "Max value",
                    data: data.map((i) =>
                      i.hist.length ? Math.max(...i.hist) : 0
                    ),
                  },
                  {
                    backgroundColor: color,
                    borderColor: color,
                    description: "Average value",
                    data: data.map((i) =>
                      i.hist.length
                        ? (
                            [...i.hist].reduce((a, b) => a + b) /
                            [...i.hist].length
                          ).toFixed(2)
                        : 0
                    ),
                  },
                ]
              : [
                  {
                    backgroundColor: color,
                    borderColor: color,
                    description: "Skewness ",
                    data: data.map((i) => this.setSkewnessValue(i)),
                  },
                ],
        },
      };
    },
  },
  mounted() {
    if (this.skewnessHistory) {
      this.setMappedHistSkewnessData();
    }
  },
};
</script>
<style>
.vc-brown {
  --vc-accent-50: #f0f9ff;
  --vc-accent-100: #e0f2fe;
  --vc-accent-200: #decbaf;
  --vc-accent-300: #decbaf;
  --vc-accent-400: #decbaf;
  --vc-accent-500: #9a8053;
  --vc-accent-600: #9a8053;
  --vc-accent-700: #9a8053;
  --vc-accent-800: #9a8053;
  --vc-accent-900: #9a8053;
}
</style>
