<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    class="w-8 h-8 my-4 cursor-pointer"
    @click="isDatepickerModalShow = true"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
    />
  </svg>

  <ModalComponent
    small
    :is-open="isDatepickerModalShow"
    @handleCancel="handleCancel"
    @handleAccept="handleAccept"
  >
    <template v-slot:title>
      <p
        class="w-full md:w-[400px] text-[#5A5A5F] text-[21px] font-semibold text-center mb-4"
      >
        {{ $t("comparison.Select dates to check history") }}
      </p>
    </template>
    <template v-slot:content>
      <div class="mx-2">
        <VDatePicker
          :locale="calendarLocale"
          expanded
          color="brown"
          :attributes="attributes"
          :initial-page="initialCalendarPage"
          v-model.range="range"
          @drag="dragValue = $event"
        />
        <div
          class="w-full h-[24px] mt-2 flex justify-center items-center text-[#5A5A5F] text-[16px]"
        >
          <span v-if="formattedEndTo" class="font-semibold">
            {{ $t("comparison.Selected dates") }}:
            <span class="font-bold mx-1">{{ formattedStartFrom }}</span> -
            <span class="ml-2 font-bold">{{ formattedEndTo }}</span>
          </span>
        </div>
      </div>
    </template>
  </ModalComponent>
</template>
<script>
import ModalComponent from "@/components/ModalComponent.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { format } from "date-fns";

export default {
  name: "ComparisonCalendar",
  components: { ModalComponent },
  data() {
    return {
      range: {
        start: null,
        end: null,
      },
      dragValue: null,
      isDatepickerModalShow: false,
    };
  },
  computed: {
    ...mapGetters(["trainingDates"]),
    calendarLocale() {
      return this.$i18n.locale.toLowerCase();
    },
    initialCalendarPage() {
      if (this.trainingDates.length) {
        const year = this.trainingDates[this.trainingDates.length - 1].slice(
          0,
          4
        );
        const month = this.trainingDates[this.trainingDates.length - 1].slice(
          5,
          7
        );
        return {
          month: month,
          year: year,
        };
      } else {
        const date = new Date();
        return {
          month: date.getMonth() + 1,
          year: date.getFullYear(),
        };
      }
    },
    attributes() {
      return [
        {
          highlight: true,
          dates: this.trainingDates,
        },
      ];
    },
    formattedStartFrom() {
      return this.range.start ? format(this.range.start, "dd MMM yyyy") : null;
    },
    formattedEndTo() {
      return this.range.end ? format(this.range.end, "dd MMM yyyy") : null;
    },
  },
  methods: {
    ...mapActions([
      "getSkewnessHistory",
      "getEnergyCoastHistory",
      "getSelectedDayTrainingsData",
    ]),
    ...mapMutations(["SET_LOADING"]),
    async handleAccept() {
      this.mappedHistData = [];
      this.SET_LOADING(true);
      this.isDatepickerModalShow = false;
      if (this.range.start) {
        const props = {
          fromDate: format(this.range.start, "yyyy-MM-dd"),
          toDate: format(this.range.end, "yyyy-MM-dd"),
          horseId: this.$route.params.id,
          id: this.$route.params.id,
        };
        await this.getSkewnessHistory(props);
        await this.getEnergyCoastHistory(props);
        await this.getSelectedDayTrainingsData(props);
      }
      this.SET_LOADING(false);
    },
    handleCancel() {
      this.isDatepickerModalShow = false;
      this.range.start = null;
      this.range.end = null;
      this.mappedHistData = [];
    },
  },
};
</script>
