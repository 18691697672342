<template>
  <div
    v-if="!loading && !isDataForComparingExist"
    class="w-full mx-auto my-4 p-4 flex justify-between flex-wrap bg-white shadow rounded-lg relative"
  >
    <div class="w-full my-8 flex justify-center items-center">
      <span>{{ $t("comparison.Nothing to compare") }}.</span>
    </div>
  </div>

  <LoaderComponent v-if="loading" />

  <div v-if="!loading && isDataForComparingExist">
    <div class="flex flex-wrap">
      <div
        v-for="item of multicharts"
        :key="item.scaleID"
        class="flex items-center ml-4 mb-4"
      >
        <input
          type="checkbox"
          class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
          :style="{ color: item.color }"
          :id="item.title"
          v-model="item.checked"
        />
        <label
          :for="item.title"
          class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2 font-bold border-gray-700"
        >
          <span>{{ $t(item.title) }}</span>
        </label>
      </div>
    </div>

    <div class="w-full relative mx-auto p-4 flex bg-white shadow rounded-lg">
      <Line
        :data="chartData"
        :options="options"
        ref="multiChart"
        id="chart"
        class="w-full min-h-[400px]"
      />
    </div>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";

import annotationPlugin from "chartjs-plugin-annotation";
import { CrosshairPlugin } from "chartjs-plugin-crosshair";
import { Line, Scatter } from "vue-chartjs";
import zoomPlugin from "chartjs-plugin-zoom";
import { mapGetters } from "vuex";

import { generateZeroLine } from "@/components/training/tracking/trackingComponents/multiChart/multiChartUtils";
import LoaderComponent from "@/components/LoaderComponent.vue";

import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { trackingModeKeys } from "@/components/training/constants";
import routeNames from "@/router/routes";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  annotationPlugin,
  zoomPlugin,
  CrosshairPlugin
);

export default {
  name: "MultipleChartComponent",
  components: {
    LoaderComponent,
    Line,
    // eslint-disable-next-line vue/no-unused-components
    Scatter,
  },
  props: {
    mappedData: Array,
    multicharts: Array,
  },

  data() {
    return {
      routeNames,

      isDataReady: false,
      chartData: {
        datasets: [],
      },
      originalData: [],
      legendWithData: [],
    };
  },

  watch: {
    async mappedData() {
      this.isDataReady = false;
      this.setDataProperties();
      this.isDataReady = true;

      setTimeout(() => {
        this.toggleChartInMultichart();
      }, 0);
    },
    multicharts: {
      handler: function () {
        this.toggleChartInMultichart();
      },
      deep: true,
    },
    trackingMode() {
      this.setDataProperties();
    },
  },

  computed: {
    ...mapGetters([
      "loading",
      "selectedDayTrainings",
      "trackingMode",
      "compareTrainingFrom",
      "compareTrainingTo",
      "selectedDayTrainingsMetadata",
    ]),

    isDataForComparingExist() {
      return this.chartData?.labels?.length > 1;
    },

    options() {
      return {
        animation: false,
        onClick: (event, item, chart) => {
          const xScale = chart.scales.x;
          const xValue = xScale.getValueForPixel(event.x);

          this.goToTrainingPage(this.mappedData[xValue].trainingId);
        },
        plugins: {
          crosshair: {
            sync: {
              enabled: false,
            },
            zoom: {
              enabled: false,
            },
            line: {
              color: "gray",
              dashPattern: [5, 5],
            },
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "xy",
              overScaleMode: "y",
            },
            zoom: {
              wheel: { enabled: true, modifierKey: "ctrl" },
              pinch: { enabled: true },
              mode: "xy",
              overScaleMode: "y",
            },
          },
          datalabels: false,
          decimation: true,
          colors: { forceOverride: true },
          annotation: {
            annotations: {
              annotation: {
                type: "line",
                borderColor: "gray",
                borderWidth: 2,
                scaleID: "x",
                value: 0,
                adjustScaleRange: false,
              },
              zeroLine: this.generateZeroLine("y"),
              zeroLine1: this.generateZeroLine("y1"),
              zeroLine2: this.generateZeroLine("y2"),
              zeroLine3: this.generateZeroLine("y3"),
              zeroLine4: this.generateZeroLine("y4"),
              zeroLine5: this.generateZeroLine("y5"),
              zeroLine6: this.generateZeroLine("y6"),
              zeroLine7: this.generateZeroLine("y7"),
              zeroLine8: this.generateZeroLine("y8"),
              zeroLine9: this.generateZeroLine("y9"),
            },
          },
          tooltip: {
            intersect: false,
            enabled: false,
            callbacks: {
              // convert numbers seconds data to the string view
              label: (context) => {
                if (context.dataset.yAxisID === "y1") {
                  const totalSeconds = context.raw;
                  const minutes = Math.floor(totalSeconds / 60);
                  const seconds = totalSeconds % 60;
                  const formattedTime = `${
                    minutes < 10 ? "0" + minutes : minutes
                  }:${seconds < 10 ? "0" : ""}${seconds}`;
                  return `${context.dataset.label}: ${formattedTime}`;
                }
              },
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        height: 400,
        interaction: {
          mode: "index",
          axis: "x",
          intersect: false,
          display: false,
        },
        scales: {
          x: {
            type: "category",
            ticks: {
              color: "gray",
            },
          },
          y: this.generateAxisOptions("y", "training.Training total distance"),
          y1: this.generateAxisOptions("y1", "training.Training duration"),
          y2: this.generateAxisOptions("y2", "training.Total energy expended"),
          y3: this.generateAxisOptions("y3", "training.Average speed"),
          y4: this.generateAxisOptions("y4", "training.Max speed"),
          y5: this.generateAxisOptions("y5", "training.Max acceleration"),
          y6: this.generateAxisOptions(
            "y6",
            "training.Average touch stiffness"
          ),
          y7: this.generateAxisOptions("y7", "training.Average HR"),
          y8: this.generateAxisOptions("y8", "training.Max HR"),
          y9: this.generateAxisOptions("y9", "tracking.Jump count"),
        },
      };
    },

    distance() {
      if (this.trackingMode === trackingModeKeys.GPS) {
        return this.mappedData.map((i) =>
          Math.ceil(i.total_distance_gps_meters)
        );
      }
      return this.mappedData.map((i) => Math.ceil(i.total_distance_ml_meters));
    },
    duration() {
      if (this.trackingMode === trackingModeKeys.GPS) {
        return this.mappedData.map((i) => i.total_training_duration_GPS);
      }
      return this.mappedData.map((i) => i.total_training_duration_ML);
    },
    energy() {
      return this.mappedData.map((i) =>
        (i.total_energy_expended / 1000).toFixed(2)
      );
    },
    averageSpeed() {
      if (this.trackingMode === trackingModeKeys.GPS) {
        return this.mappedData.map((i) => i.total_average_speed_GPS.toFixed(2));
      }
      return this.mappedData.map((i) => i.total_average_speed_ML.toFixed(2));
    },
    maxSpeed() {
      if (this.trackingMode === trackingModeKeys.GPS) {
        return this.mappedData.map((i) => i.total_max_speed_GPS.toFixed(2));
      }
      return this.mappedData.map((i) => i.total_max_speed_ML.toFixed(2));
    },
    maxAcceleration() {
      return this.mappedData.map((i) => i.total_max_acceleration_ML.toFixed(2));
    },
    avgTouchStiffness() {
      return this.mappedData.map((i) =>
        i.total_average_stiffness_power.toFixed(2)
      );
    },
    avgHR() {
      return this.mappedData.map((i) => i.hr_average);
    },
    maxHR() {
      return this.mappedData.map((i) => i.hr_max);
    },
    jumpCount() {
      return this.mappedData.map((i) => i.JUMP_count);
    },
  },

  methods: {
    generateZeroLine,

    getMultichartColor(id) {
      return this.multicharts.filter((i) => i.scaleId === id)[0].color;
    },

    generateAxisOptions(id, label) {
      return {
        id: id,
        type: "linear",
        position: "left",
        ticks: {
          color: this.getMultichartColor(id),
        },
        border: {
          color: this.getMultichartColor(id),
        },
        grid: {
          color: this.getMultichartColor(id),
          drawOnChartArea: false,
        },
        title: {
          position: "left",
          display: false,
          text: this.$t(label),
          color: this.getMultichartColor(id),
          font: {
            weight: "bold",
          },
          padding: { top: 20, left: 0, right: 0, bottom: 0 },
        },
      };
    },

    setFormattedToTimezoneTime(dateString) {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const zonedDate = utcToZonedTime(new Date(dateString), userTimeZone);
      const time = format(zonedDate, "HH:mm");
      const days = format(zonedDate, "dd.MM");
      const time_parts = time.split(":");
      const millisecond = time_parts[0] * (60000 * 60) + time_parts[1] * 60000;
      const userTz = new Date().getTimezoneOffset() * 60000;
      const milliseconds = millisecond + Math.abs(userTz);

      let minutes = Math.floor(milliseconds / 3600000);
      let seconds = ((milliseconds % 3600000) / 60000).toFixed(0);

      return `${days} ${minutes < 10 ? "0" + minutes : minutes}:${
        seconds < 10 ? "0" : ""
      }${seconds}`;
    },

    goToTrainingPage(trainingId) {
      const currentUrl = window.location.href;
      const newUrl = currentUrl.replace(this.routeNames.comparison, trainingId);
      window.location.href = newUrl;
    },

    setDataProperties() {
      const labels = this.mappedData.map((i) =>
        this.setFormattedToTimezoneTime(i.created)
      );

      this.chartData = {
        labels,
        datasets: [
          {
            label: this.$t("training.Training total distance"),
            data: this.distance,
            borderColor: this.getMultichartColor("y"),
            backgroundColor: this.getMultichartColor("y"),
            yAxisID: "y",
            display: false,
          },
          {
            label: this.$t("training.Training duration"),
            data: this.duration,
            borderColor: this.getMultichartColor("y1"),
            backgroundColor: this.getMultichartColor("y1"),
            yAxisID: "y1",
            display: false,
          },
          {
            label: this.$t("training.Total energy expended"),
            data: this.energy,
            borderColor: this.getMultichartColor("y2"),
            backgroundColor: this.getMultichartColor("y2"),
            yAxisID: "y2",
            display: false,
          },
          {
            label: this.$t("training.Average speed"),
            data: this.averageSpeed,
            borderColor: this.getMultichartColor("y3"),
            backgroundColor: this.getMultichartColor("y3"),
            yAxisID: "y3",
            display: false,
          },
          {
            label: this.$t("training.Max speed"),
            data: this.maxSpeed,
            borderColor: this.getMultichartColor("y4"),
            backgroundColor: this.getMultichartColor("y4"),
            yAxisID: "y4",
            display: false,
          },
          {
            label: this.$t("training.Max acceleration"),
            data: this.maxAcceleration,
            borderColor: this.getMultichartColor("y5"),
            backgroundColor: this.getMultichartColor("y5"),
            yAxisID: "y5",
            display: false,
          },
          {
            label: this.$t("training.Average touch stiffness"),
            data: this.avgTouchStiffness,
            borderColor: this.getMultichartColor("y6"),
            backgroundColor: this.getMultichartColor("y6"),
            yAxisID: "y6",
            display: false,
          },
          {
            label: this.$t("training.Average HR"),
            data: this.avgHR,
            borderColor: this.getMultichartColor("y7"),
            backgroundColor: this.getMultichartColor("y7"),
            yAxisID: "y7",
            display: false,
          },
          {
            label: this.$t("training.Max HR"),
            data: this.maxHR,
            borderColor: this.getMultichartColor("y8"),
            backgroundColor: this.getMultichartColor("y8"),
            yAxisID: "y8",
            display: false,
          },
          {
            label: this.$t("tracking.Jump count"),
            data: this.jumpCount,
            borderColor: this.getMultichartColor("y9"),
            backgroundColor: this.getMultichartColor("y9"),
            yAxisID: "y9",
            display: false,
          },
        ],
      };
    },

    async toggleChartInMultichart() {
      if (this.$refs.multiChart) {
        await this.$nextTick();
        const selectedChartIds = this.multicharts
          .filter((i) => i.checked)
          .map((i) => i.scaleId);
        const chart = this.$refs.multiChart.chart;

        chart.data.datasets.forEach((dataset, idx) => {
          if (selectedChartIds.includes(dataset.yAxisID)) {
            chart.data.datasets[idx].hidden = false;
            chart.options.scales[dataset.yAxisID].display = true;
          } else {
            chart.data.datasets[idx].hidden = true;
            chart.options.scales[dataset.yAxisID].display = false;
          }
        });
        chart.update();
      }
    },

    handleKeyDown(event) {
      const { key } = event;
      if (key === "Alt") {
        const chart = this.$refs.multiChart.chart;
        chart.options.plugins.tooltip.enabled = true;
        chart.update();
      }
    },
    handleKeyUp(event) {
      const { key } = event;
      if (key === "Alt") {
        const chart = this.$refs.multiChart.chart;
        chart.options.plugins.tooltip.enabled = false;
        chart.update();
      }
    },
  },

  mounted() {
    if (this.$refs.multiChart) {
      this.setDataProperties();
    }
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
  },

  beforeUnmount() {
    if (this.$refs.multiChart) {
      document.removeEventListener("keydown", this.handleKeyDown);
      document.removeEventListener("keyup", this.handleKeyUp);
    }
  },
};
</script>
