<template>
  <HeaderComponent @emitSwitchSidebar="isSidebarOpen = !isSidebarOpen"/>
  <SidebarComponent :isSidebarOpen="isSidebarOpen">
    <slot></slot>
  </SidebarComponent>
  <FooterComponent/>
</template>

<script>

import HeaderComponent from "@/components/blocks/header/HeaderComponent.vue";
import SidebarComponent from "@/components/blocks/sidebar/SidebarComponent.vue";
import FooterComponent from "@/components/blocks/FooterComponent.vue";

export default {
  name: "HomeLayoutComponent",
  components: {
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
  },
  data() {
    return {
      isSidebarOpen: true,
    }
  },
}
</script>