<template>
  <HomeLayoutComponent>
  </HomeLayoutComponent>
</template>
<script>
import HomeLayoutComponent from "@/components/layouts/HomeLayoutComponent";

export default {
  name: "HomePage",
  components: {
    HomeLayoutComponent,
  }
}
</script>