<template>
  <router-view />
</template>
<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["getSettings"]),
    setLocale() {
      const locale = localStorage.getItem("horseapp-lang");
      this.$i18n.locale = locale ? locale : "En";
    },
  },
  async mounted() {
    this.setLocale();
    await this.getSettings();
  },
};
</script>
<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
</style>
