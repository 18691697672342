<template>
  <div
    class="w-full self-start flex flex-col items-center justify-start font-monrope"
  >
    <div class="flex items-center mb-4">
      <ComparisonCalendar />
      <SelectControl
        v-if="Boolean(commonsData[0]?.total_distance_gps_meters)"
        :style="{
          flexDirection: 'row',
          marginBottom: '0',
          marginLeft: '16px',
          width: 'auto',
        }"
        dataKey="trackingMode"
        :data="trackingMode"
        :options="trackingModesOptions"
        @update="handleTrackingMode"
      />
    </div>
    <div class="mb-4">
      <nav
        class="isolate flex flex-wrap divide-x divide-gray-200 rounded-lg shadow"
        aria-label="Tabs"
      >
        <button
          v-for="(tab, tabIdx) in tabs"
          :key="tab.name"
          :class="buttonClass(tabIdx)"
          @click="handleSwitchTab(tabIdx)"
        >
          <span>{{ $t(tab.name) }}</span>
          <span aria-hidden="true" :class="indicatorClass(tab)" />
        </button>
      </nav>
    </div>

    <div class="w-full" v-show="tabs[0].current">
      <MultipleChartComponent
        :mappedData="commonsData"
        :multicharts="multicharts"
      />
    </div>
    <div class="w-full" v-show="tabs[1].current">
      <SkewnessComparisonComponent />
    </div>
    <div class="w-full" v-show="tabs[2].current">
      <CumulativeEnergyComparison />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import MultipleChartComponent from "@/components/training/comparison/multiChart/MultipleChartComponent.vue";
import SkewnessComparisonComponent from "@/components/training/comparison/SkewnessComparisonComponent.vue";
import CumulativeEnergyComparison from "@/components/training/comparison/CumulativeEnergyComparison.vue";
import ComparisonCalendar from "@/components/training/comparison/ComparisonCalendar.vue";
import { showErrorAndGoToHomePage } from "@/store/storeUtils";
import {
  trackingModeKeys,
  trackingModes,
} from "@/components/training/constants";
import SelectControl from "@/components/UI/SelectControl.vue";

export default {
  name: "TrainingsComparisonComponent",
  components: {
    SelectControl,
    ComparisonCalendar,
    MultipleChartComponent,
    SkewnessComparisonComponent,
    CumulativeEnergyComparison,
  },
  data() {
    return {
      trackingModes,
      tabs: [
        { name: "comparison.Multichart", current: true },
        { name: "comparison.Skewness and hist history", current: false },
        { name: "comparison.Cumulative energy costs history", current: false },
      ],
      trainings: {},
      commonsData: [],
      multicharts: [],
    };
  },
  computed: {
    ...mapGetters([
      "trainingDates",
      "loading",
      "horsesList",
      "horseTrackingCard",
      "currentTrainingMetadata",
      "selectedCompareTrainingFrom",
      "selectedCompareTrainingTo",
      "reportsCompareTrainingFrom",
      "reportsCompareTrainingTo",
      "selectedChartsList",
      "selectedDayTrainings",
      "trackingMode",
      "GPSDatafiles",
    ]),
    trackingModesOptions() {
      return this.commonsData[0]?.average_speed_gps
        ? this.trackingModes
        : [trackingModeKeys.ML];
    },
    allWalkEnergyCoast() {
      return this.getEnergyCost("walk");
    },
    allTrotEnergyCoast() {
      return this.getEnergyCost("trot");
    },
    allGallopEnergyCoast() {
      return this.getEnergyCost("gallop");
    },
  },
  watch: {
    selectedDayTrainings(newVal) {
      this.commonsData = newVal.map((i) => {
        const data = i.results.find(
          (result) => result.type === "TRAINING_COMMONS"
        );
        return {
          ...data.rowResult,
          created: data.created,
          trainingId: data.trainingId,
        };
      });
    },
    multicharts: {
      handler: async function (newVal) {
        localStorage.setItem(
          "selected-multicharts-by-days",
          JSON.stringify(newVal)
        );
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      "getHorsesList",
      "getSelectedDayTrainingsData",
      "handleTrackingMode",
    ]),
    ...mapMutations(["SET_HORSE_TRACKING_CARD"]),

    buttonClass(tabIdx) {
      return [
        tabIdx === 0 ? "rounded-l-lg" : "",
        tabIdx === this.tabs.length - 1 ? "rounded-r-lg" : "",
        "group relative min-w-[380px] flex-1 overflow-hidden bg-white py-4 px-4 text-center hover:bg-gray-50 focus:z-10 text-[#5A5A5F] text-[18px] font-semibold",
      ];
    },
    indicatorClass(tab) {
      return [
        tab.current ? "bg-indigo-500" : "bg-transparent",
        "absolute inset-x-0 bottom-0 h-0.5",
      ];
    },
    getEnergyCost(gait) {
      const datasets =
        this.reportsCompareTrainingFrom.EXPENDITURE_ENERGY_METRICS
          ?.TOTAL_BY_GAIT_AND_SENSORS;
      const toDatasets =
        this.reportsCompareTrainingTo.EXPENDITURE_ENERGY_METRICS
          ?.TOTAL_BY_GAIT_AND_SENSORS;
      if (datasets && toDatasets) {
        return `${datasets[gait].BACK.toFixed(2)} - ${toDatasets[
          gait
        ].BACK.toFixed(2)}`;
      }
      return 0;
    },
    async setCurrentHorse() {
      await this.$nextTick();
      if (this.horsesList) {
        const currentHorse = this.horsesList.find(
          (h) => h.id === Number(this.$route.params.id)
        );
        if (!currentHorse) {
          return showErrorAndGoToHomePage("Horse not found");
        }
        this.SET_HORSE_TRACKING_CARD(currentHorse);
      }
    },
    async changeTraining(data) {
      this.trainings[data.type] = data;
    },
    handleSwitchTab(index) {
      this.tabs.forEach((tab, tabIdx) => (tab.current = tabIdx === index));
    },
  },

  beforeMount() {
    const defaultMulticharts = [
      {
        title: this.$t("training.Training total distance"),
        scaleId: "y",
        checked: true,
        color: "#d53e4f",
      },
      {
        title: this.$t("training.Training duration"),
        scaleId: "y1",
        checked: true,
        color: "#f46d43",
      },
      {
        title: this.$t("training.Total energy expended"),
        scaleId: "y2",
        checked: true,
        color: "#fdae61",
      },
      {
        title: this.$t("training.Average speed"),
        scaleId: "y3",
        checked: true,
        color: "#fee08b",
      },
      {
        title: this.$t("training.Max speed"),
        scaleId: "y4",
        checked: true,
        color: "#bc9b6a",
      },
      {
        title: this.$t("training.Max acceleration"),
        scaleId: "y5",
        checked: true,
        color: "#abdda4",
      },
      {
        title: this.$t("training.Average touch stiffness"),
        scaleId: "y6",
        checked: true,
        color: "#66c2a5",
      },
      {
        title: this.$t("training.Average HR"),
        scaleId: "y7",
        checked: true,
        color: "#3288bd",
      },
      {
        title: this.$t("training.Max HR"),
        scaleId: "y8",
        checked: true,
        color: "#5e4fa2",
      },
      {
        title: this.$t("tracking.Jump count"),
        scaleId: "y9",
        checked: true,
        color: "#00704a",
      },
    ];

    let selectedMulticharts = localStorage.getItem(
      "selected-multicharts-by-days"
    );
    let selectedTrackingMode = localStorage.getItem("tracking-mode");
    if (selectedMulticharts && selectedTrackingMode) {
      selectedMulticharts = JSON.parse(selectedMulticharts);
    } else {
      selectedMulticharts = defaultMulticharts;
      selectedTrackingMode = trackingModeKeys.GPS;
      localStorage.setItem(
        "selected-multicharts-by-days",
        JSON.stringify(selectedMulticharts)
      );
      localStorage.setItem("tracking-mode", selectedTrackingMode);
    }

    this.multicharts = selectedMulticharts;
    this.handleTrackingMode({ data: selectedTrackingMode });
  },

  async mounted() {
    if (!this.horsesList) await this.getHorsesList(this.$route.params.page);
    if (!this.horseTrackingCard) await this.setCurrentHorse();
  },
};
</script>

<style lang="scss" scoped>
:deep(#trackingMode) {
  height: 40px;
  width: auto;
  font-size: 14px;
}
</style>
