<template>
  <div class="flex flex-col mb-8 text-[#5A5A5F] w-full">
    <label :for="dataKey" class="mb-2 text-sm">{{ label }}</label>
    <select
      :id="dataKey"
      :name="dataKey"
      class="h-[56px] w-full rounded border border-[#B6B6BB] appearance-none focus:ring-0 focus:ring-offset-0 placeholder:text-[#D9D9DF] text-md font-semibold"
      v-model="fieldData"
      @change="handleChange"
    >
      <option
        v-for="option of options"
        :key="option"
        :value="option"
        class="text-[#5A5A5F] font-semibold p-4"
      >
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SelectControl",
  props: {
    label: String,
    dataKey: String,
    data: String,
    isDisabled: Boolean,
    options: Array,
  },
  data() {
    return {
      fieldData: "value2",
    };
  },
  watch: {
    data() {
      this.fieldData = this.data;
    },
  },
  mounted() {
    this.fieldData = this.data ? this.data : this.options[0];
    this.$emit("update", { key: this.dataKey, data: this.fieldData });
  },
  methods: {
    handleChange() {
      this.$emit("update", { key: this.dataKey, data: this.fieldData });
    },
  },
};
</script>
